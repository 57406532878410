<template>
    <a href="javascript:void(0);"
        data-toggle="popover"
        title="Sonderangebote"
        data-trigger="click"
        data-placement="top"
        data-html="true"
        :data-content="getOfferText">
        <span class="offer-left">Sonderangebote</span>
    </a>

</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'offer-help',
    	props: {
    		offers: Array
    	},
    	mounted: function() {
    		$('[data-toggle="tooltip"]').tooltip();
    		$('[data-toggle="popover"]').popover();
    		$('body').on('click', function(e) {
    			$('[data-toggle=popover]').each(function() {
    				if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
    					(($(this).popover('hide').data('bs.popover') || {}).inState || {}).click = false;
    				}
    			});
    		});
    	},
    	computed: {

    		getOfferText: function() {
    			var offerText = '';
    			console.log('offers:' + this.offers);
    			if (this.offers) {
    				this.offers.sort((a, b) => (a.fromdate > b.fromdate) ? 1 : -1);
    				var i;
    				offerText = "<ul>";
    				for (i = 0; i < this.offers.length; i++) {
    					offerText += "<li>";
    					console.log('this.offers[i].discountType::' + this.offers[i].discountType);
    					if (this.offers[i].discountType === 'PERCENT') {
    						offerText += "<div class='percent-offer'>";
    						if (this.offers[i].type === 'EARLYBIRD' && this.offers[i].name) {
    							offerText += "<span><span class='offer-name'>" + this.offers[i].name + "</span> <span class='offer-condition'>- " + this.offers[i].value / 100 + "% Rabatt</span>";
    							if (this.offers[i].timeSpan === 1) {
    								offerText += "<span class='offer-condition'> bei Anreise in frühestens einem Tag</span>";
    							} else if (this.offers[i].timeSpan > 1) {
    								offerText += "<span class='offer-condition'> bei Anreise in frühestens " + this.offers[i].timeSpan + " Tagen </span>";
    							}

    							if (this.offers[i].fromdate && this.offers[i].tilldate) {
    								offersText += "<span>von</span> " + DateEx.formatDate(this.offers[i].fromdate, undefined, VOFFICE.mlang) + " <span>bis</span> " + DateEx.formatDate(this.offers[i].tilldate, undefined, VOFFICE.mlang);
    							}

    							if (this.offers[i].minStay > 1) {
    								offerText += "<span class='minstay'>(min. " + this.offers[i].minStay + " ÜN)</span>";
    							}
    							offerText += "</span>";
    						} else if (this.offers[i].type === 'LASTMINUTE') {
    							offerText += "<span><span class='offer-name'>" + this.offers[i].name + "</span> <span class='offer - condition'>- " + this.offers[i].value / 100 + " % Rabatt</span>";
    							if (this.offers[i].timeSpan === 1) {
    								offerText += "<span class='offer-condition'> - 1 Tag vor Anreise buchbar </span>";
    							} else if (this.offers[i].timeSpan > 1) {
    								offerText += "<span class='offer-condition'> -" + this.offers[i].timeSpan + " Tage vor Anreise buchbar </span>";
    							}

    							if (this.offers[i].fromdate && this.offers[i].tilldate) {
    								offerText += "<span>von</span> " + DateEx.formatDate(this.offers[i].fromdate, undefined, VOFFICE.mlang) + " <span>bis</span> " + DateEx.formatDate(this.offers[i].tilldate, undefined, VOFFICE.mlang);
    							}
    							if (this.offers[i].minStay > 1) {
    								offerText += "<span class='minstay'>(min. " + this.offers[i].minStay + " ÜN)</span>";
    							}
    							offerText += "</span>";
    						} else {
    							offerText += "<span><span class='offer-name'>" + this.offers[i].name + "</span> <span class='offer - condition'>- " + this.offers[i].value / 100 + " % Rabatt</span>";
    							if (this.offers[i].timeSpan === 1) {
    								offerText += "<span class='offer-condition'> - 1 Tag vor Anreise buchbar </span>";
    							} else if (this.offers[i].timeSpan > 1) {
    								offerText += "<span class='offer-condition'> -" + this.offers[i].timeSpan + " Tage vor Anreise buchbar </span>";
    							}

    							if (this.offers[i].fromdate && this.offers[i].tilldate) {
    								offerText += "<span>von</span> " + DateEx.formatDate(this.offers[i].fromdate, undefined, VOFFICE.mlang) + " <span>bis</span> " + DateEx.formatDate(this.offers[i].tilldate, undefined, VOFFICE.mlang);
    							}
    							if (this.offers[i].minStay > 1) {
    								offerText += "<span class='minstay'>(min. " + this.offers[i].minStay + " ÜN)</span>";
    							}
    							offerText += "</span>";
    						}
    						offerText += "</div>";
    					} else if (this.offers[i].discountType === 'XY') {
    						offerText += "<div  class='xy-offer'>";
    						offerText += "<span class='offer-name'>" + this.offers[i].name + "</span> - ";
    						if (this.offers[i].fromdate && this.offers[i].tilldate) {
    							offerText += "<span>von</span> " + DateEx.formatDate(this.offers[i].fromdate, undefined, VOFFICE.mlang) + " <span>bis</span> " + DateEx.formatDate(this.offers[i].tilldate, undefined, VOFFICE.mlang);
    						}
    						offerText += "</div>";

    					}
    					offerText += "</li>";


    				}
    				offerText += "</ul>";
    			}

    			return offerText;
    		}
    	}
    };
</script>