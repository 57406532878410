var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "voffice-popup-box",
    {
      staticClass: "full-xxs",
      attrs: {
        "trigger-id": _vm.triggerId,
        "apply-label": _vm.applyLabel,
        "close-btn-disabled": _vm.closeBtnDisabled
      },
      on: { closed: _vm.onClosed }
    },
    [
      _c(
        "div",
        { staticClass: "datepicker-trigger" },
        [
          _c("airbnb-style-datepicker", {
            attrs: {
              "trigger-element-id": _vm.triggerId,
              "close-after-select": true,
              inline: true,
              "show-shortcuts-menu-trigger": false,
              "show-action-buttons": false,
              "offset-y": 10,
              mode: "range",
              "fullscreen-mobile": false,
              "disabled-dates": _vm.disabledDays,
              "customized-dates": _vm.calClasses,
              "min-date": _vm.minDate,
              "end-date": _vm.maxDate,
              "date-one": _vm.from,
              "date-two": _vm.till,
              "mobile-header": _vm.mobileHeader
            },
            on: {
              opened: _vm.onOpened,
              closed: _vm.onClosed,
              "date-one-selected": _vm.onFromSelected,
              "date-two-selected": _vm.onTillSelected
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "legend-cal" }, [
            _c("ul", { staticClass: "first" }, [
              _c("li", { staticClass: "text" }, [_vm._v("Legende:")]),
              _vm._v(" "),
              _c("li", [
                _c("i", { staticClass: "day day-full" }),
                _c("span", [_vm._v("Belegt")])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("i", { staticClass: "day day-free" }),
                _c("span", [_vm._v("Frei")])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("i", { staticClass: "day day-end" }),
                _c("span", [_vm._v("Nur einchecken")])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("i", { staticClass: "day day-start" }),
                _c("span", [_vm._v("Nur auschecken")])
              ])
            ]),
            _vm._v(" "),
            _c("ul", { staticClass: "sec" }, [
              _c("li", [
                _c("i", { staticClass: "day day-disable" }),
                _c("span", [
                  _vm._v(
                    "An- oder Abreise online nicht möglich. Bitte kontaktieren Sie uns."
                  )
                ])
              ])
            ])
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }