<script>
    import V from 'voUtils/V.js'



    export default {
    	voVueComponent: 'feedback-carousel',
    	props: {
    		carouselId: String
    	},
    	data() {
    		return {
    			// Static reviews, please use dynamic if available in the future
    			reviews: [{
    				url: './Ostsee/Luebecker.Bucht/Timmendorfer.Strand/2_2.MorgenStern',
    				unit: 'TECHTS STRANDHAUS – App. Morgenstern',
    				feedback: 'Ein wunderschönes Appartement mit schöner Ausstattung. Nichts hat gefehlt (Handtücher, Seife, Geschirrtücher usw. alles vorhanden). Die Lage ist perfekt, ca. 100m zum Strand und ins Zentrum. Es war ein schöner traumhafter Urlaub für die gesamte Familie! Wir kommen gerne wieder!'
    			}, {
    				url: './Ostsee/Luebecker.Bucht/Timmendorfer.Strand/1_8.Wellenreiter',
    				unit: 'TECHTS APARTMENTHAUS – App. Wellenreiter',
    				feedback: 'Wir waren bereits zum zweiten Mal im Apartment "Wellenreiter". Die liebevolle, mit kleinen Details ausgestattete Wohnung, das super Boxspringbett, die Terrasse mit dem Blick in den wunderschönen Garten, der freundliche Empfang bei Ankunft und Abreise - alles, auch das tolle Wetter - hat uns einen tollen Aufenthalt und super Erholung gebracht.'
    			}, {
    				url: './Ostsee/Luebecker.Bucht/Timmendorfer.Strand/1_3.Sonnenschein',
    				unit: 'TECHTS APARTMENTHAUS – App. Sonnenschein',
    				feedback: 'Wir hatten den „Sonnenschein“, im wahrsten Sinne, einfach super! Tolle und geschmackvolle Einrichtung, viel Platz für 2 Personen, mit SUV ausgelegter Tiefgarage, komplette Kücheneinrichtung u.a. auch mit Nespressomaschine: alles da! Das hat natürlich seinen Preis, der aber durchaus gerechtfertigt ist. Schöner Balkon mit Liege, Blick in den Garten und schöner Abendsonne: einfach perfekt und wir kommen sehr gern wieder!'
    			}, {
    				url: './Ostsee/Luebecker.Bucht/Timmendorfer.Strand/2_6.KuestenLiebe',
    				unit: 'TECHTS STRANDHAUS – App Küstenliebe',
    				feedback: 'Sehr schöne gepflegte Wohnung, modern und im nordischen Stil eingerichtet. Ein schöner Balkon mit Esstisch und bequemen Stühlen. Fahrstuhl und reservierter Parkplatz sind vorhanden. Zentral aber doch relativ ruhig, nahe bei Strand und Zentrum. Alle Geschäfte sind gut zu Fuß erreichbar. Die Vermieter sind sehr nett, zuvorkommend und hilfsbereit.'
    			}, {
    				url: './Ostsee/Luebecker.Bucht/Timmendorfer.Strand/3_1..Kuestenlicht',
    				unit: 'TECHTS LANDHUS – App Küstenlicht',
    				feedback: 'Wir hatten das Appartement Küstenlicht gebucht. Es hat uns an nichts gefehlt, das Appartement ist sehr, sehr schön, es ist gut gelegen und bestens ausgestattet. Der Service war super, sehr freundlich, sehr hilfsbereit und sehr kompetent. Sehr zu empfehlen!'
    			}, ]
    		}
    	},
    	mounted: function() {
    		$("#owl-" + this.carouselId).owlCarousel({
    			items: 1,
    			loop: true,
    			autoplay: true,
    			dots: true,
    			smartSpeed: 3000,
    			nav: false,
    			autoplayTimeout: 10000
    		});
    	}
    };
</script>