<script>
    import V from 'voUtils/V.js'



    export default {
    	voVueComponent: 'owl-carousel',
    	props: {
    		carouselId: String,
    		items: {
    			type: String,
    			default: "1"
    		},
    		items0: {
    			type: String,
    			default: "1"
    		},
    		items767: {
    			type: String,
    			default: "1"
    		},
    		loop: {
    			type: String,
    			default: "false"
    		},
    		autoplay: {
    			type: String,
    			default: "false"
    		},
    		autoplaytimeout: {
    			type: String,
    			default: "8000"
    		},
    		dots: {
    			type: String,
    			default: "false"
    		},
    		speed: {
    			type: String,
    			default: "5000"
    		},
    		nav: {
    			type: String,
    			default: "false"
    		},
    		nav0: {
    			type: String,
    			default: "false"
    		},
    		nav767: {
    			type: String,
    			default: "false"
    		},
    		slidemargin: {
    			type: String,
    			default: '0'
    		},
    		dots0: {
    			type: String,
    			default: "false"
    		},
    		dots767: {
    			type: String,
    			default: "false"
    		}
    	},

    	mounted: function() {

    		var itemsVal = parseInt(this.items);
    		var item0Val = parseInt(this.items0);
    		var item767Val = parseInt(this.items767);
    		var marginVal = parseInt(this.slidemargin);
    		var loopVal = (this.loop == 'true');
    		var autoplayVal = (this.autoplay == 'true');
    		var dotsVal = (this.dots == 'true');
    		var speedVal = parseInt(this.speed);
    		var navVal = (this.nav == 'true');
    		var nav0Val = (this.nav0 == 'true');
    		var nav767Val = (this.nav767 == 'true');
    		var dots0Val = (this.dots0 == 'true');
    		var dots767Val = (this.dots767 == 'true');
    		var autoplayTimeoutVal = parseInt(this.autoplaytimeout);

    		$("#owl-" + this.carouselId).owlCarousel({
    			items: itemsVal,
    			margin: marginVal,
    			loop: loopVal,
    			autoplay: autoplayVal,
    			autoplayTimeout: autoplayTimeoutVal,
    			dots: dotsVal,
    			smartSpeed: speedVal,
    			nav: navVal,

    			responsive: {
    				0: {
    					items: item0Val,
    					nav: nav0Val,
    					dots: dots0Val
    				},
    				767: {
    					items: item767Val,
    					nav: nav767Val,
    					dots: dots767Val
    				},
    				1000: {
    					items: itemsVal,
    					nav: navVal,
    					dots: dotsVal
    				}
    			}
    		});
    	}
    };
</script>