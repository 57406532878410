<template>
    <voffice-popup-box :trigger-id="triggerId"
        class="full-xxs"
        :apply-label="applyLabel"
        :close-btn-disabled="closeBtnDisabled"
        @closed="onClosed">

        <div class="datepicker-trigger">

            <airbnb-style-datepicker :trigger-element-id="triggerId"
                :close-after-select="true"
                :inline="true"
                :show-shortcuts-menu-trigger="false"
                :show-action-buttons="false"
                :offset-y="10"
                :mode="'range'"
                :fullscreen-mobile="false"
                :disabled-dates="disabledDays"
                :customized-dates="calClasses"
                :min-date="minDate"
                :end-date="maxDate"
                :date-one="from"
                :date-two="till"
                @opened="onOpened"
                @closed="onClosed"
                :mobile-header="mobileHeader"
                @date-one-selected="onFromSelected"
                @date-two-selected="onTillSelected" />

            <div class="legend-cal">
                <ul class="first">
                    <li class="text">Legende:</li>
                    <li><i class="day day-full"></i><span>Belegt</span></li>
                    <li><i class="day day-free"></i><span>Frei</span></li>

                    <li><i class="day day-end"></i><span>Nur einchecken</span></li>
                    <li><i class="day day-start"></i><span>Nur auschecken</span></li>
                </ul>
                <ul class="sec">
                    <li><i class="day day-disable"></i><span>An- oder Abreise online nicht möglich. Bitte kontaktieren Sie uns.</span></li>
                </ul>
            </div>

        </div>
    </voffice-popup-box>

</template>

<script>
    import {
    	isBlank
    }
    from 'voUtils/tools.js';
    import V from 'voUtils/V.js'


    import DateEx from 'voUtils/DateEx.js'

    export default {
    	voVueComponent: 'voffice-daterange-legend',
    	voMsgCodes: [
    		'weekdayslist.short',
    		'monthlist.full',
    		'weekdayslist',
    		'night.pl',
    		'min.label',
    		'max.label',
    	],
    	data() {
    		return {
    			needTill: false,
    			nextArrivalDate: undefined,
    			disabledArrivalDays: [],
    			disabledDepartureDays: [],
    			minNights: undefined,
    			maxNights: undefined,
    			calClasses: [],
    			cal: undefined
    		}
    	},
    	props: {
    		from: String,
    		till: String,
    		nights_min: Number,
    		nights_max: Number,
    		triggerId: String,
    		fullscreenMobile: Boolean,
    		applyLabel: String,
    		getCal: Function,
    	},
    	computed: {
    		disabledDays: function() {
    			return this.needTill ? this.disabledDepartureDays : this.disabledArrivalDays
    		},
    		closeBtnDisabled: function() {
    			return false
    		},
    		possibleNights: function() {
    			if (this.from && this.till) {
    				let days = DateEx.getDays(this.till, this.from)
    				let result = []
    				for (let i = 1; i <= days; i++) {
    					result.push({
    						value: i,
    						text: i + ' ' + this.voMsgPl('night.pl', i)
    					})
    				}
    				console.log("result", result, days)
    				return result
    			} else {
    				return undefined
    			}

    		},
    		mobileHeader: function() {
    			if (this.needTill) {
    				return this.voMsg("daterange.till");
    			} else {
    				return this.voMsg("daterange.from");
    			}
    		},
    		minDate: function() {
    			if (this.needTill) {
    				return DateEx.plusDays(this.from, 1);
    			} else {
    				return DateEx.interfaceFormat(DateEx.getToday());
    			}

    		},
    		maxDate: function() {
    			if (this.needTill) {
    				return this.nextArrivalDate;
    			} else {
    				return undefined;
    			}

    		}

    	},

    	methods: {
    		onOpened: function() {

    			if (this.getCal && !this.cal) {

    				this.getCal((cal) => {
    					this.cal = cal;
    					var day = DateEx.localDate(cal.availabilityUpdate);

    					var arrivals = [];
    					var departures = [];
    					var notAvailable = [];

    					var last;
    					for (let a of cal.availability) {
    						if (a == 'Y') {
    							if (last == 'N' || last == 'Q') {
    								departures.push(DateEx.interfaceFormat(day));
    							}
    						} else {
    							if (last == 'Y') {
    								arrivals.push(DateEx.interfaceFormat(day));
    							} else {
    								notAvailable.push(DateEx.interfaceFormat(day));
    							}
    						}
    						day = DateEx.plusDays(day, 1)
    						last = a;
    					}

    					this.calClasses = [{
    						cssClass: 'arrival',
    						dates: arrivals
    					}, {
    						cssClass: 'departure',
    						dates: departures
    					}, {
    						cssClass: 'not-available',
    						dates: notAvailable
    					}];


    					var disabledArrivalDays = []
    					var day = DateEx.localDate(cal.availabilityUpdate);
    					if (cal.changeOver !== null) {
    						for (let a of cal.changeOver) {
    							if (a == 'X' || a == 'O') {
    								disabledArrivalDays.push(DateEx.interfaceFormat(day));
    							}
    							day = DateEx.plusDays(day, 1)
    						}
    					}

    					this.disabledArrivalDays = disabledArrivalDays;

    				});
    			}
    		},
    		onClosed: function() {
    			setTimeout(() => {
    				this.$emit('closed');
    			}, 50);
    		},
    		onFromSelected: function(d) {
    			this.needTill = true;
    			this.$emit('update:from', d);
    			this.$emit('update:till', undefined);


    		},
    		onTillSelected: function(d) {
    			this.needTill = isBlank(d) && this.from;
    			this.$emit('update:till', d);
    			setTimeout(() => {
    				this.$emit('on-tilldate-selected');
    				if (!isBlank(d)) {
    					/*$(".box").removeClass("show");
    					this.onClosed();
    					this.$emit("toggle-show");*/
    					$('#heading').trigger('click');
    				}
    			}, 50);
    		},
    		updateSelectedNights: function() {
    			if (this.from && this.till) {
    				if (!this.nights_min) {
    					this.minNights = DateEx.getDays(this.till, this.from)
    				} else {
    					this.minNights = this.nights_min;
    				}
    				if (!this.nights_max) {
    					this.maxNights = this.minNights
    				} else {
    					this.maxNights = this.nights_max
    				}

    			}


    		}
    	},
    	watch: {
    		from: function() {
    			this.updateSelectedNights()
    		},
    		till: function() {
    			this.updateSelectedNights()
    		},
    		minNights: function() {
    			this.$emit('update:nights_min', this.minNights);
    			if (this.maxNights && this.maxNights < this.minNights) {
    				this.maxNights = this.minNights
    			}
    		},
    		maxNights: function() {
    			this.$emit('update:nights_max', this.maxNights);
    			if (this.minNights && this.minNights > this.maxNights) {
    				this.minNights = this.maxNights
    			}
    		},


    	}
    }
</script>

<style scoped>
    .datepicker-trigger {
    	--cal_not_available: #cd7898;
    }

    .asd__day--not-available {
    	background: var(--cal_not_available) !important;
    }

    .asd__day--arrival {
    	background: linear-gradient(90deg, white 70%, var(--cal_not_available) 80%);
    }

    .asd__day--departure {
    	background: linear-gradient(90deg, var(--cal_not_available) 20%, white 30%);
    }
</style>